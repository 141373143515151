import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
// import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/',
    name: 'cloudnotes',
    component: () => import('@/views/cloudnotes/index'),
    meta: { title: 'cloudnotes', show: 'cloudnotes', hidden: true, }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/index'),
    meta: { title: '多元产品', show: 'product' }
  },
  {
    path: '/teachers',
    name: 'teachers',
    component: () => import('@/views/teachers/index'),
    meta: { title: '教学体系', show: 'teachers' }
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/activity/index'),
    meta: { title: '活动资讯', show: 'activity', },
  },
  {
    path: "/activity/detail",
    name: 'activityDetail',
    component: () => import("@/views/activity/detail"),
    meta: { hidden: true, show: 'activity' },
  },
  {
    path: '/us',
    name: 'us',
    component: () => import('@/views/us/index'),
    meta: { title: '关于我们', show: 'us', }
  },
  {
    path: '/down',
    name: 'down',
    component: () => import('@/views/down/index'),
    meta: { hidden: true, show: 'down' },
  },
  {
    path: '/slideshow',
    name: 'slideshow',
    component: () => import('@/views/down/index'),
    meta: { hidden: true, show: 'slideshow' },
  },
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = createRouter()
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
