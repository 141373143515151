import request from '@/utils/request'


// 网站配置
export function getConfig (params) {
  return request({
    url: '/api/web/getConfig',
    method: 'get',
    params
  })
}

// 获取心愿墙列表
export function getWishWallList (params) {
  return request({
    url: '/api/web/wishWallList',
    method: 'get',
    params
  })
}

// // 修改团队信息
// export function putTeamsEdit (data) {
//   return request({
//     url: '/teams/edit',
//     method: 'put',
//     data
//   })
// }

//添加心愿
export function postAddWishWall (data) {
  return request({
    url: '/api/web/addWishWall',
    method: 'post',
    data
  })
}

//文件上传
export function fileUpload (data) {
  return request({
    url: '/api/web/upload',
    method: 'post',
    data
  })
}

//老师报名
export function postAddTeacherApply (data) {
  return request({
    url: '/api/web/addTeacherApply',
    method: 'post',
    data
  })
}

//获取文章列表
export function getArticles (params) {
  return request({
    url: 'black.cn/api/information/list',
    method: 'get',
    params
  })
}

//获取文章内容
export function getDetail (params) {
  return request({
    url: 'black.cn/api/information/detail',
    method: 'get',
    params
  })
}

//提交领取课程信息
export function postSubscribe (data) {
  return request({
    url: 'class.cn/subscribe/other_yum',
    method: 'post',
    data
  })
}

//获取验证码
export function sendSMSCode (data) {
  return request({
    url: 'class.cn/sendSMSCode',
    method: 'post',
    data
  })
}
