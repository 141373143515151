<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>



<script>
export default {
  name: 'App',
  created() {
    var _hmt = _hmt || []
    ;(function () {
      var hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?6c4e8bfd61f0499a503eb409688de7ec'
      var s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    })()
  },
}
</script>

<style>
#app {
  min-width: 1200px;
}
</style>
