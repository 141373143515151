import Vue from 'vue'
import '@/styles/index.scss'
import App from './App.vue'
import router from './router'
import animated from 'animate.css'
import store from './store'
Vue.use(animated)


import api from '@/api'
Vue.prototype.$http = api

Vue.config.productionTip = false


const _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
(function () {
  const hm = document.createElement('script');
  hm.src = 'https://hm.baidu.com/hm.js?6c4e8bfd61f0499a503eb409688de7ec';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(hm, s);
}());


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
