import axios from 'axios'
const service = axios.create({
  baseURL: 'http://192.168.8.190:8080',
  timeout: 30000
})
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'

    let regs = /black.cn/
    if (regs.test(config.url)) {
      config.baseURL = 'https://kuro.cloudnotes.com.cn/',
        config.url = config.url.replace(regs, '')
    }

    let regsTwo = /class.cn/
    if (regsTwo.test(config.url)) {
      config.baseURL = 'https://admin.cloudnotes.com.cn/api/site',
        config.url = config.url.replace(regsTwo, '')
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code == 200 || res.code == 201 || res.code == 1) {
      return res.data || res.list
    }
    if (res.code !== 1) {
      alert('Error:' + res.msg)
      return Promise.reject(res)
    } else {
      return res.data
    }
  },
  error => {
    const res = error.response.data
    alert(res.msg || res.message || '网络错误')
    if (error.response.status == 401) {
    } else {
      return Promise.reject(error)
    }
  }
)

export default service
